const activities = [
    { value: 'swimming', label: 'Swimming' },
    { value: 'party', label: 'Party' },
    { value: 'studying', label: 'Studying' },
    { value: 'biking', label: 'Biking' },
    { value: 'hiking', label: 'Hiking' },
    { value: 'table tennis', label: 'Table Tennis' },
    { value: 'basket ball', label: 'Basket Ball' },
    { value: 'gaming', label: 'Gaming' },
    { value: 'others', label: 'Others' },
];
// window.aaa = activities;
module.exports = { activities };
